<template>
  <div @click="renderizarComponente">
    <app-collapse-item
      ref="collapseItem"
      :render="false"
      :title="grupo.descricao"
      class="mb-2 shadow rounded"
    >
      <template slot="header">
        <TabPrecificacaoHeader
          :vacina="vacina"
          :grupo="grupo"
        />
      </template>
      
      <p v-if="!render">Processando...</p>
      <p v-else>
        <b-row class="py-2 px-1">
          <b-col lg="6" md="6" sm="12" class="border-right">
            <TabPrecificacaoValorEmpresa
              :moneyMask="moneyMask"
              :vacina="vacina"
              :grupo="grupo"
              @update="updateKey"
            />

            <div
              v-for="operador in grupo.operador"
              :key="operador.id_departamento"
            >
              <TabPrecificacaoValorOperador
                v-if="operador.ativo"
                :moneyMask="moneyMask"
                :vacina="vacina"
                :grupo="grupo"
                :operador="operador"
                @update="updateKey"
              />
            </div>
          </b-col>
          <b-col lg="6" md="6" sm="12">
            <p class="m-0">Documentação e contratos</p>
            <TabPrecificacaoDocumento :vacina="vacina" :grupo="grupo" />
          </b-col>
        </b-row>
      </p>
    </app-collapse-item>
  </div>
</template>

<script>
import { BCol, BRow } from "bootstrap-vue";
import AppCollapse from "@core/components/app-collapse/AppCollapse.vue";
import AppCollapseItem from "@core/components/app-collapse/AppCollapseItem.vue";
import TabPrecificacaoHeader from "./TabPrecificacaoHeader.vue";
import TabPrecificacaoValorEmpresa from "./TabPrecificacaoValorEmpresa.vue";
import TabPrecificacaoValorOperador from "./TabPrecificacaoValorOperador.vue";
import TabPrecificacaoDocumento from "./TabPrecificacaoDocumento.vue";
export default {
  components: {
    AppCollapse,
    AppCollapseItem,
    BCol,
    BRow,
    TabPrecificacaoHeader,
    TabPrecificacaoValorEmpresa,
    TabPrecificacaoValorOperador,
    TabPrecificacaoDocumento,
  },
  props: {
    grupo: {
      type: Object,
      required: true,
    },
    vacina: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      render: false,
      key: 0,
      moneyMask: {
        decimal: ",",
        thousands: ".",
        precision: 2,
      },
    };
  },
  methods: {
    renderizarComponente() {
      if (!this.render) {
        this.render = true;
      }
    },
    updateKey(val) {
      this.key = val;
    },
  },
};
</script>
